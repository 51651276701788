import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "create-an-auth-provider"
    }}>{`Create an auth provider`}</h1>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` aims to support many authentication providers. We've designed an abstraction layer that hopefully makes this achievable.`}</p>
    <p>{`As of v1.0.0 there's built-in support for `}<a parentName="p" {...{
        "href": "https://docs.netlify.com/visitor-access/identity/"
      }}>{`Netlify Identity`}</a>{` and `}<a parentName="p" {...{
        "href": "https://auth0.com/"
      }}>{`Auth0`}</a>{`. Consider this a proof-of-concept that the abstraction layer works :)`}</p>
    <p>{`You can create a provider to contribute to `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{`'s codebase, or to make it work with your existing authentication infrastructure. It should be possible to make work with traditional cookie/session based authentication. 🤞`}</p>
    <p><em parentName="p">{`If you're fond of your auth service, `}<strong parentName="em">{`please contribute`}</strong>{`. It would mean the world to me`}</em>{` ❤️`}</p>
    <h2 {...{
      "id": "what-is-an-auth-provider"
    }}>{`What is an auth provider`}</h2>
    <p>{`An auth provider is a service that authenticates users. Like Auth0, Netlify Identity, AWS Cognito, Firebase Auth, and others.`}</p>
    <p><inlineCode parentName="p">{`useAuth`}</inlineCode>{` uses auth wrappers to interact with these services in a uniform way. Each wrapper is a class of this type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// The shape of auth provider wrappers
export interface AuthProviderClass {
    // addDefaultParams: (
    //     props: Omit<AuthOptions, "dispatch">
    // ) => Omit<AuthOptions, "dispatch">;
    authorize(): void;
    signup(): void;
    logout(returnTo?: string): void;
    handleLoginCallback(
        dispatch: PayloadSender<AnyEventObject>
    ): Promise<boolean>;
    checkSession(): Promise<{
        user: Auth0UserProfile;
        authResult: Auth0DecodedHash;
    }>;
    userId(user: AuthUser): string | null;
    userRoles(user: AuthUser): string[] | null;
}
`}</code></pre>
    <p>{`To maintain interoperability, `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` avoids interacting with services directly. If it can't fit in those methods, open a bug :)`}</p>
    <h2 {...{
      "id": "abstract-implementation"
    }}>{`Abstract implementation`}</h2>
    <p>{`You can use this as a starting point for your auth provider wrapper.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Auth Wrapper for Auth0
export class Auth0 implements AuthProviderClass {
    private auth0: Auth0Client.WebAuth;
    private dispatch: (eventName: string, eventData?: any) => void;
    // Auth0 specific, used for roles
    private customPropertyNamespace?: string;

    // Initialize the client and save any custom config
    constructor(params: AuthOptions) {
        // You will almost always need access to dispatch
        this.dispatch = params.dispatch;
        // Auth0 specific, used for roles
        this.customPropertyNamespace = params.customPropertyNamespace;

        // Init your client
        this.auth0 = new Auth0Client.WebAuth({
            ...(params as Auth0Options)
        });
    }

    // Makes configuration easier by guessing default options
    static addDefaultParams(params: ProviderOptions, callbackDomain: string) {
        const vals = params as Auth0Options;

        return {
            redirectUri: \`\${callbackDomain}/auth0_callback\`,
            audience: \`https://\${vals.domain}/api/v2/\`,
            responseType: "token id_token",
            scope: "openid profile email",
            ...vals
        };
    }

    public authorize() {
        // Open login dialog
    }

    public signup() {
        // Open signup dialog
    }

    public logout(returnTo?: string) {
        // Logs user out of the underlying service
    }

    public userId(user: Auth0UserProfile): string {
        // Return the userId from Auth0 shape of data
    }

    public userRoles(user: AuthUser): string[] | null {
        // Return user roles from Auth0 shape of data
    }

    public async handleLoginCallback(): Promise<boolean> {
        // Handle login data after redirect back from service
        // Dispatch ERROR on error
        // Dispatch AUTHENTICATED on success
        // include the user object and authResult with at least an expiresIn value
    }

    public async checkSession(): Promise<{
        user: Auth0UserProfile;
        authResult: Auth0DecodedHash;
    }> {
        // verify session is still valid
        // return fresh user info
    }
}
`}</code></pre>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`You can see the current list of `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useAuth/tree/master/src/providers"
      }}>{`implemented auth providers on GitHub`}</a></p>
    <p>{`Use them to guide your implementation :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      